import { Link } from "react-router-dom";
import { useState } from "react";


export const Carousel = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return(
  <>
    <section className="swiper-slider-hero relative block h-screen" id="home">
      <div className="swiper-container absolute end-0 top-0 w-full h-full">
        <div className="swiper-wrapper">
          <div className="swiper-slide flex items-center overflow-hidden">
            <div
              className="slide-inner absolute end-0 top-0 w-full h-full slide-bg-image flex items-center bg-center;"
              data-background="assets/images/corporate/1.jpg"
            >
              <div className="absolute inset-0 bg-black/70"></div>
              <div className="container relative">
                <div className="grid grid-cols-1">
                  <div className="text-center">
                    <h1 className="font-semibold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">
                      AI Enabled Applications <br /> for Your Business{" "}
                    </h1>
                    <p className="text-white/70 text-lg max-w-xl mx-auto">
                      At Nexient AI, we deliver tailored AI-driven software
                      solutions to drive growth and efficiency for your
                      business.
                    </p>

                    <div className="mt-6">
                      <Link
                        to="/Contact"
                        className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                      >
                        Contact us
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="swiper-button-next bg-transparent size-[35px] leading-[35px] -mt-[30px] bg-none border border-solid border-white/50 text-white hover:bg-indigo-600 hover:border-indigo-600 rounded-full text-center"></div>
        <div className="swiper-button-prev bg-transparent size-[35px] leading-[35px] -mt-[30px] bg-none border border-solid border-white/50 text-white hover:bg-indigo-600 hover:border-indigo-600 rounded-full text-center"></div>
      </div>
    </section>

    <section id="services" class="relative bg-gray-50 dark:bg-slate-800 md:py-24 py-16">
    <div class="container relative md:mt-1 mt-1">
        <div class="grid grid-cols-1 pb-8 text-center">
            <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Services</h3>
            <p class="text-slate-400 max-w-xl mx-auto">
                Transform Your Business with Our Advanced AI Solutions: Engineered for Precision, Performance, and Future-Ready Innovation.
            </p>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-[30px]">
            <div class="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-chat-bubble-user"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">AI and Custom Chatbots</h5>
                    <p class="text-slate-400 mt-3">
                        Revolutionize customer interaction with personalized AI chatbots tailored to your business needs.
                    </p>
                </div>
            </div>
            <div class="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-chart-line"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">Machine Learning</h5>
                    <p class="text-slate-400 mt-3">
                        Harness the power of data to drive actionable insights and make informed decisions with our machine learning solutions.
                    </p>
                </div>
            </div>
            <div class="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-chart-growth"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">Predictive Analysis</h5>
                    <p class="text-slate-400 mt-3">
                        Anticipate future trends and behaviors to stay ahead of the curve with our predictive analysis services.
                    </p>
                </div>
            </div>
            <div class="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-brackets-curly"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">Customized Software</h5>
                    <p class="text-slate-400 mt-3">
                        Unlock the full potential of your business with bespoke software solutions designed to fit your unique requirements.
                    </p>
                </div>
            </div>
            <div class="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-mobile-android"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">Customized Mobile Apps</h5>
                    <p class="text-slate-400 mt-3">
                        Enhance customer engagement and accessibility with customized mobile applications crafted for seamless user experiences.
                    </p>
                </div>
            </div>
            <div class="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-processor"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">AI Natural Language Processing</h5>
                    <p class="text-slate-400 mt-3">
                        Transform unstructured data into actionable insights and automate language-based tasks with our AI natural language processing capabilities.
                    </p>
                </div>
            </div>
            <div class="p-6 hover:shadow-xl hover:shadow-gray-100 dark:hover:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-cloud"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">Cloud Solutions and Integrations</h5>
                    <p class="text-slate-400 mt-3">
                        Enhance scalability and efficiency with our secure cloud solutions and seamless integrations tailored to your business needs.
                    </p>
                </div>
            </div>
            <div class="p-6 shadow-xl shadow-gray-100 dark:shadow-gray-800 duration-500 rounded-2xl mt-6 text-center">
                <div class="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                    <i class="uil uil-chart-line"></i>
                </div>
                <div class="content mt-7">
                    <h5 class="title h5 text-lg font-medium">Automated Sales Funnel and Digital Marketing</h5>
                    <p class="text-slate-400 mt-3">
                        Optimize your sales process and enhance customer engagement with our automated sales funnel solutions and comprehensive digital marketing strategies.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


    <section id="about" class="relative md:py-24 py-16">
      <div class="container relative">
        <div class="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
          <div class="lg:col-span-4 md:col-span-6 lg:order-1 order-2 lg:text-center">
            <h6 class="text-indigo-600 text-sm font-bold uppercase mt-8 mb-2">
              About Us
            </h6>
            <h3 class="mb-4 md:text-4xl md:leading-normal text-3xl leading-normal font-bold">
              Who we are ?
            </h3>
            <p class="text-slate-400 max-w-xl">
              At Nexient AI, while we are a newly established company, our team
              boasts decades of combined experience in the AI and technology
              sector. Our experts have been at the forefront of innovation,
              delivering cutting-edge solutions and driving success across
              various industries.{" "}
            </p>
          </div>

          <div class="lg:col-span-4 md:col-span-6 lg:order-2 order-1">
            <img
              src="assets/images/company/about2.png"
              class="shadow-md dark:shadow-gray-800"
              alt=""
            />
          </div>

          <div class="lg:col-span-4 md:col-span-12 lg:order-3 order-2">
            <p class="text-slate-400">
              With a strong foundation in AI, machine learning, and software
              development, we are committed to transforming businesses through
              intelligent solutions.
            </p>
            <ul class="list-none text-slate-400 mt-4">
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                Customized AI Chatbots
              </li>
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                Machine Learning Insights
              </li>
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                Predictive Analysis Services
              </li>
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                Cloud Solutions and Integrations
              </li>
              <li class="mb-1 flex">
                <i class="uil uil-check-circle text-indigo-600 text-xl me-2"></i>{" "}
                Customized Software and Mobile Apps Development
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section id="industries" class="relative md:py-24 py-16">
      <div class="container relative md:mt-24 mt-16 pt-16">
        <div class="absolute inset-0 opacity-25 dark:opacity-50 bg-[url('../../assets/images/map.html')] bg-no-repeat bg-center bg-cover"></div>

        <div class="relative grid grid-cols-1 pb-8 text-center">
          <h3 class="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
            The Industries We Serve
          </h3>

          <p class="text-slate-400 max-w-xl mx-auto">
            We provide cutting-edge AI software solutions tailored to various
            industries, ensuring innovation and efficiency.
          </p>
        </div>

        <div class="relative grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
          <div class="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
            <i class="uil uil-wallet text-3xl text-indigo-600"></i>
            <div class="content mt-6">
              <a
                href="#"
                class="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out"
              >
                Banking & Finance
              </a>
              <p class="text-slate-400 mt-4">
                Enhance financial services with AI-driven insights and
                automation for improved decision-making and customer
                experiences.
              </p>
            </div>
          </div>

          <div class="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
            <i class="uil uil-restaurant text-3xl text-indigo-600"></i>
            <div class="content mt-6">
              <a
                href="#"
                class="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out"
              >
                Food & Commodities
              </a>
              <p class="text-slate-400 mt-4">
                Optimize supply chains and improve quality control with AI
                solutions tailored for the food and commodities sector.
              </p>
            </div>
          </div>

          <div class="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
            <i class="uil uil-estate text-3xl text-indigo-600"></i>
            <div class="content mt-6">
              <a
                href="#"
                class="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out"
              >
                Real Estate
              </a>
              <p class="text-slate-400 mt-4">
                Transform property management and client interactions with
                AI-powered analytics and personalized services.
              </p>
            </div>
          </div>

          <div class="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
            <i class="uil uil-hospital text-3xl text-indigo-600"></i>
            <div class="content mt-6">
              <a
                href="#"
                class="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out"
              >
                Healthcare
              </a>
              <p class="text-slate-400 mt-4">
                Enhance patient care and operational efficiency with AI-driven
                healthcare solutions tailored to your needs.
              </p>
            </div>
          </div>

          <div class="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
            <i class="uil uil-headphones-alt text-3xl text-indigo-600"></i>
            <div class="content mt-6">
              <a
                href="#"
                class="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out"
              >
                Customer Support
              </a>
              <p class="text-slate-400 mt-4">
                Improve customer satisfaction and efficiency with AI-powered
                chatbots and automated support systems.
              </p>
            </div>
          </div>

          <div class="group px-6 py-8 bg-white dark:bg-slate-900 shadow hover:shadow-lg dark:hover:shadow-gray-800 duration-500 ease-in-out border-t-[3px] border-gray-100 dark:border-gray-700 hover:border-indigo-600 dark:hover:border-indigo-600">
            <i class="uil uil-shopping-cart text-3xl text-indigo-600"></i>
            <div class="content mt-6">
              <a
                href="#"
                class="text-xl font-semibold hover:text-indigo-600 duration-500 ease-in-out"
              >
                Retail & E-commerce
              </a>
              <p class="text-slate-400 mt-4">
                Boost sales and customer engagement with AI solutions for
                personalized shopping experiences and inventory management.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="faq" className="relative md:py-24 py-16">
      <div className="grid grid-cols-1 pb-8 text-center">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
          Frequently Asked Questions
        </h3>
        <p className="text-slate-400 max-w-xl mx-auto">
          Discover everything you need to know about IntelliXBot. Get insights, understand its features, and learn how it can revolutionize your business operations.
        </p>
      </div>

      <div className="font-nunito text-base text-black dark:text-white dark:bg-slate-900">
        <div className="md:flex justify-center">
          <div className="lg:w-2/3 md:w-full">
            <div id="accordion-collapse" className="mt-6">
              {[1, 2, 3, 4, 5, 6].map((index) => (
                <div
                  key={index}
                  className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4"
                >
                  <h2
                    className={`text-base font-semibold bg-white dark:bg-slate-900 ${
                      activeIndex === index
                        ? "border-b border-gray-300 dark:border-gray-700"
                        : ""
                    }`}
                    id={`accordion-collapse-heading-${index}`}
                  >
                    <button
                      type="button"
                      className="flex justify-between items-center p-5 w-full font-medium text-start"
                      onClick={() => handleAccordionClick(index)}
                      aria-expanded={activeIndex === index}
                      aria-controls={`accordion-collapse-body-${index}`}
                    >
                      <span>
                        {index === 1 && "How does artificial intelligence benefit businesses?"}
                        {index === 2 && "What are some examples of AI applications in business?"}
                        {index === 3 && "How can AI improve customer engagement?"}
                        {index === 4 && "What are the challenges of implementing AI in business?"}
                        {index === 5 && "How can I ensure the security of AI-powered systems?"}
                        {index === 6 && "What are the key considerations when selecting an AI solution for my business?"}
                      </span>
                      <svg
                        data-accordion-icon
                        className={`size-4 ${activeIndex === index ? "rotate-180" : ""} shrink-0`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </h2>
                  <div
                    id={`accordion-collapse-body-${index}`}
                    className={`transition-all duration-500 ${
                      activeIndex === index ? "block" : "hidden"
                    } bg-white dark:bg-slate-900`}
                    aria-labelledby={`accordion-collapse-heading-${index}`}
                  >
                    <div className="p-5">
                      <p className="text-slate-400 dark:text-gray-400">
                        {index === 1 && "Artificial intelligence (AI) helps businesses streamline processes, enhance decision-making, personalize customer experiences, and gain insights from data to drive growth and innovation."}
                        {index === 2 && "Examples of AI applications in business include chatbots for customer service, predictive analytics for sales forecasting, recommendation systems for personalized marketing, and natural language processing for sentiment analysis."}
                        {index === 3 && "AI can improve customer engagement by providing personalized recommendations, answering customer queries in real-time through chatbots, analyzing customer feedback to enhance products or services, and delivering tailored marketing messages based on customer preferences and behavior."}
                        {index === 4 && "Challenges of implementing AI in business include data privacy concerns, lack of skilled personnel, integration with existing systems, ethical considerations, and the need for continuous training and optimization of AI models."}
                        {index === 5 && "To ensure the security of AI-powered systems, businesses can implement robust cybersecurity measures, conduct regular security audits, encrypt sensitive data, monitor system activities for anomalies, and stay updated on emerging threats and security best practices."}
                        {index === 6 && "Key considerations when selecting an AI solution for your business include the specific business needs and objectives, the scalability and flexibility of the solution, integration capabilities with existing systems, the vendor's reputation and support services, data privacy and security features, and the total cost of ownership."}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)};
