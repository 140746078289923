import React from "react";
import { Header } from "./Header";
import { Carousel } from "./Carousel";
import { Footer } from "./Footer";

const HomePage = () => (
  <>
    <Header />
    <Carousel />
    {/* <Business /> */}
    {/* <Pricing /> */}
    {/* <FAQDetails /> */}
    <Footer />
  </>
);

export default HomePage;
