import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { Login } from "./components/Login";
import feather from "feather-icons";
import HomePage from "./components/Homepage";
import { TermsServices } from "./components/TermsServices";
import { Privacy } from "./components/Privacy";
import {Contact} from "./components/Contact"
import {ContactUs} from "./components/ContactUs"

import ScrollToTop from './components/ScrollToTop';

function App() {
  React.useEffect(() => {
    feather.replace();
  }, []);

  return (
    <Router>
          <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/terms" element={<TermsServices />}></Route>
        <Route path="/privacy" element={<Privacy />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/contactus" element={<ContactUs />}></Route>

      </Routes>
    </Router>
  );
}

export default App;
