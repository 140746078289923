import { ContactHeader } from "./ContactHeader";
import { Footer } from "./Footer";
import { Header } from "./Header";

export const TermsServices = () => (
  <>
    {/* Start Navbar */}
    <ContactHeader />
    {/* End Navbar */}

    {/* Start Hero */}
    <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
      <div className="container relative">
        <div className="grid grid-cols-1 text-center mt-10">
          <h3 className="text-3xl leading-normal font-semibold">
            Terms and Conditions
          </h3>
        </div>
        {/* End grid */}
      </div>
      {/* End container */}
      <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul className="tracking-[0.5px] mb-0 inline-block">
          <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
            <a href="index.html">Techwind</a>
          </li>
        
         
          <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
            <i className="uil uil-angle-right-b" />
          </li>
          <li
            className="inline-block uppercase text-[13px] font-bold text-indigo-600"
            aria-current="page"
          >
            Terms and Conditions
          </li>
        </ul>
      </div>
    </section>
    {/* End Hero */}

    <div className="relative">
      <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg
          className="w-full h-auto scale-[2.0] origin-top"
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    {/* End Hero */}

    {/* Start Terms & Conditions */}
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <div className="md:flex justify-center">
          <div className="md:w-3/4">
            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
              <h5 className="text-xl font-semibold mb-4">Introduction :</h5>
              <p className="text-slate-400">
                Please read these terms and conditions carefully before using our website and services.
              </p>
              
              <h5 className="text-xl font-semibold mb-4 mt-8">1. Agreement to Terms :</h5>
              <p className="text-slate-400">
                By accessing or using our website, you agree to be bound by these terms and conditions.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">2. Intellectual Property :</h5>
              <p className="text-slate-400">
                The content on this website, including but not limited to text, graphics, logos, images, and software, is the property of Nexient Software Solutions (OPC) Pvt. Ltd. and is protected by copyright laws.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">3. Use License :</h5>
              <p className="text-slate-400">
                Permission is granted to temporarily download one copy of the materials on Nexient Software Solutions (OPC) Pvt. Ltd.'s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license, you may not:
              </p>
              <ul className="list-none text-slate-400 mt-3">
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Modify or copy the materials;
                </li>
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Use the materials for any commercial purpose or for any public display (commercial or non-commercial);
                </li>
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Attempt to decompile or reverse engineer any software contained on Nexient Software Solutions (OPC) Pvt. Ltd.'s website;
                </li>
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Remove any copyright or other proprietary notations from the materials; or
                </li>
                <li className="flex mt-2">
                  <i className="uil uil-arrow-right text-indigo-600 text-lg align-middle me-2" />
                  Transfer the materials to another person or "mirror" the materials on any other server.
                </li>
              </ul>

              <h5 className="text-xl font-semibold mb-4 mt-8">4. Disclaimer :</h5>
              <p className="text-slate-400">
                The materials on Nexient Software Solutions (OPC) Pvt. Ltd.'s website are provided on an 'as is' basis. Nexient Software Solutions (OPC) Pvt. Ltd. makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">5. Limitations :</h5>
              <p className="text-slate-400">
                In no event shall Nexient Software Solutions (OPC) Pvt. Ltd. or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Nexient Software Solutions (OPC) Pvt. Ltd.'s website, even if Nexient Software Solutions (OPC) Pvt. Ltd. or a Nexient Software Solutions (OPC) Pvt. Ltd. authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">6. Accuracy of Materials :</h5>
              <p className="text-slate-400">
                The materials appearing on Nexient Software Solutions (OPC) Pvt. Ltd.'s website could include technical, typographical, or photographic errors. Nexient Software Solutions (OPC) Pvt. Ltd. does not warrant that any of the materials on its website are accurate, complete, or current.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">7. Links :</h5>
              <p className="text-slate-400">
                Nexient Software Solutions (OPC) Pvt. Ltd. has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Nexient Software Solutions (OPC) Pvt. Ltd. of the site. Use of any such linked website is at the user's own risk.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">8. Modifications :</h5>
              <p className="text-slate-400">
                Nexient Software Solutions (OPC) Pvt. Ltd. may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms and Conditions of Use.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">9. Governing Law :</h5>
              <p className="text-slate-400">
                These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction in which Nexient Software Solutions (OPC) Pvt. Ltd. operates and you irrevocably submit to the exclusive jurisdiction of the courts in that location.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">10. Privacy Policy :</h5>
              <p className="text-slate-400">
                Your use of our website is also governed by our Privacy Policy, which can be found on our website. Please review our Privacy Policy to understand how we collect, use, and protect your information.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">11. Termination :</h5>
              <p className="text-slate-400">
                We reserve the right to terminate your access to the website and services at our sole discretion, without notice, for conduct that we believe violates these Terms and Conditions or is harmful to other users of the website or the business of Nexient Software Solutions (OPC) Pvt. Ltd.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">12. User Responsibilities :</h5>
              <p className="text-slate-400">
                You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account or password.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">13. Indemnification :</h5>
              <p className="text-slate-400">
                You agree to indemnify, defend, and hold harmless Nexient Software Solutions (OPC) Pvt. Ltd. and its affiliates, directors, officers, employees, and agents from and against any claims, liabilities, damages, losses, or expenses, including without limitation legal fees, arising out of or related to your use of the website or violation of these terms.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">14. Third-Party Services :</h5>
              <p className="text-slate-400">
                Our website may contain links to third-party websites or services that are not owned or controlled by Nexient Software Solutions (OPC) Pvt. Ltd. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party websites or services.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">15. Contact Information :</h5>
              <p className="text-slate-400">
                If you have any questions about these Terms and Conditions, please contact us at info@nexientai.com.
              </p>

              {/* <div className="mt-6">
                <a
                  href="#"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md"
                >
                  Accept
                </a>
                <a
                  href="#"
                  className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-indigo-600 border-indigo-600 text-indigo-600 hover:text-white rounded-md ms-2"
                >
                  Decline
                </a>
              </div> */}
            </div>
          </div>
          {/* End Terms & Conditions Content */}
        </div>
        {/* End grid */}
      </div>
      {/* End container */}
    </section>
    {/* End Terms & Conditions */}

    {/* Start Footer */}
    <Footer />
    {/* End Footer */}
  </>
);
