import React,{ useState }  from "react";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <nav id="topnav" className="defaultscroll is-sticky">
    <div className="container relative">
      <div className="menu-extras">
        <div className="menu-item">
          <a className={`navbar-toggle ${menuOpen ? "open" : ""}`} id="isToggle" onClick={toggleMenu}>
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>
      <div id="navigation" style={{ display: menuOpen ? "block" : "none" }}>
        {/* Navigation Menu */}
        <ul className="navigation-menu nav-light">
          <a className="logo" href="index.html">
            <span className="inline-block dark:hidden">
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo-dark.png`}
                className="l-dark"
                height="24"
                alt=""
              />
              <img
                src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`}
                className="l-light"
                height="24"
                alt=""
              />
            </span>
            <img
              src={`${process.env.PUBLIC_URL}/assets/images/logo-light.png`}
              height="24"
              className="hidden dark:inline-block"
              alt=""
            />
          </a>
          <li>
            <a href="/" className="sub-menu-item" onClick={closeMenu}>
              Home
            </a>
          </li>
          <li>
            <a href="#services" className="sub-menu-item" onClick={closeMenu}>
              Services
            </a>
          </li>
          <li>
            <a href="#about" className="sub-menu-item" onClick={closeMenu}>
              About
            </a>
          </li>
          <li>
            <a href="#industries" className="sub-menu-item" onClick={closeMenu}>
              Industries
            </a>
          </li>
          <li>
            <a href="#faq" className="sub-menu-item" onClick={closeMenu}>
              FAQ
            </a>
          </li>
          <li>
            <a href="/contactus" className="sub-menu-item" onClick={closeMenu}>
              Contact Us
            </a>
          </li>
        </ul>
        {/* End navigation menu */}
      </div>
      {/* End navigation */}
    </div>
    {/* End container */}
  </nav>
  )};
