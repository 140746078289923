import { Footer } from "./Footer";
import { Header } from "./Header";
import { ContactHeader } from "./ContactHeader";

export const Privacy = () => (
  <>
        <ContactHeader />
    <section className="relative table w-full py-32 lg:py-40 bg-gray-50 dark:bg-slate-800">
      <div className="container relative">
        <div className="grid grid-cols-1 text-center mt-10">
          <h3 className="text-3xl leading-normal font-semibold">
            Privacy Policy
          </h3>
        </div>
        {/*end grid*/}
      </div>
      {/*end container*/}
      <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
        <ul className="tracking-[0.5px] mb-0 inline-block">
          <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out hover:text-indigo-600">
            <a href="index.html">Techwind</a>
          </li>
          <li className="inline-block text-base text-slate-950 dark:text-white mx-0.5 ltr:rotate-0 rtl:rotate-180">
            <i className="uil uil-angle-right-b" />
          </li>
          <li
            className="inline-block uppercase text-[13px] font-bold text-indigo-600"
            aria-current="page"
          >
            Privacy Policy
          </li>
        </ul>
      </div>
    </section>
    {/*end section*/}
    <div className="relative">
      <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
        <svg
          className="w-full h-auto scale-[2.0] origin-top"
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    {/* End Hero */}

    {/* Start Privacy Policy */}
    <section className="relative md:py-24 py-16">
      <div className="container relative">
        <div className="md:flex justify-center">
          <div className="md:w-3/4">
            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
              <h5 className="text-xl font-semibold mb-4">Privacy Policy Overview :</h5>
              <p className="text-slate-400">
                This privacy policy applies to our website and services. We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">1. Introduction :</h5>
              <p className="text-slate-400">
                We respect your privacy and are committed to protecting it. This Privacy Policy explains how we collect, use, and disclose personal information when you use our website and services.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">2. Information Collected :</h5>
              <p className="text-slate-400">
                We collect personal information you provide to us, such as name, email, and address. We may also collect information automatically through cookies and similar technologies.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">3. Use of Information :</h5>
              <p className="text-slate-400">
                We use the collected information to provide and improve our services, and to communicate with you. We do not share your information with third parties except as described in this policy.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">4. Data Protection :</h5>
              <p className="text-slate-400">
                We take reasonable measures to protect your data, but cannot guarantee absolute security. In the event of a data breach, we will notify affected users as required by law.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">5. Cookies and Tracking :</h5>
              <p className="text-slate-400">
                We use cookies and similar tracking technologies to analyze website traffic and improve user experience. You can disable cookies in your browser settings, but this may affect website functionality.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">6. Third-Party Links :</h5>
              <p className="text-slate-400">
                Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these external sites. We encourage you to review their privacy policies before providing any personal information.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">7. Data Retention :</h5>
              <p className="text-slate-400">
                We retain your personal information only as long as necessary to provide our services or as required by law. When we no longer need your information, we will take reasonable steps to securely delete or anonymize it.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">8. User Rights :</h5>
              <p className="text-slate-400">
                You have the right to access, correct, or delete your personal information. If you wish to exercise these rights, please contact us as detailed below. We will respond to your request in accordance with applicable laws.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">9. Children's Privacy :</h5>
              <p className="text-slate-400">
                Our services are not directed at children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child, we will take steps to delete such information.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">10. Changes to This Policy :</h5>
              <p className="text-slate-400">
                We may update this privacy policy from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">11. International Data Transfers :</h5>
              <p className="text-slate-400">
                Your information may be transferred to and processed in countries outside of your own. By using our services, you consent to the transfer of your information to these countries, which may have different data protection laws.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">12. Data Breaches :</h5>
              <p className="text-slate-400">
                In the event of a data breach, we will promptly assess the situation and notify affected users and relevant authorities as required by applicable data protection laws.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">13. Security Measures :</h5>
              <p className="text-slate-400">
                We implement various security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">14. Contact Us :</h5>
              <p className="text-slate-400">
                If you have any questions or concerns about this privacy policy or our data practices, please contact us at info@nexientai.com. We will address your inquiries as promptly as possible.
              </p>

              <h5 className="text-xl font-semibold mb-4 mt-8">15. Effective Date :</h5>
              <p className="text-slate-400">
                This privacy policy is effective as of [Date]. Please review this policy regularly to stay informed of any updates or changes.
              </p>

              
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* End Privacy Policy */}
    <Footer />
   
  </>
);
