import React,{ useState }  from "react";

export const ContactHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <nav id="topnav" className="defaultscroll is-sticky">
    <div className="container relative">
      <div className="menu-extras">
        <div className="menu-item">
          <a className={`navbar-toggle ${menuOpen ? "open" : ""}`} id="isToggle" onClick={toggleMenu}>
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </div>
      </div>
      <div id="navigation" style={{ display: menuOpen ? "block" : "none" }}>
        {/* Navigation Menu */}
        <ul className="navigation-menu nav-light">
          <li>
            <a href="/" className="sub-menu-item" onClick={closeMenu}>
              Go Back to Home
            </a>
          </li>
        
        </ul>
        {/* End navigation menu */}
      </div>
      {/* End navigation */}
    </div>
    {/* End container */}
  </nav>
  )};
